import React from 'react';
import styled from 'styled-components/macro';
import { Text } from '@idexio/dev-idex-swap-uikit';
import { Spinner } from '../Shared';
import { AutoColumn } from '../Column';
import { Wrapper, Section, ConfirmedIcon, ContentHeader } from './helpers';
import yellowSpinner from './yellowSpinner.svg';

type ConfirmationPendingContentProps = {
  onDismiss: () => void;
  pendingText: string;
};

const CustomLightSpinner = styled(Spinner)<{ size: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`;

const ConfirmationPendingContent = ({
  onDismiss,
  pendingText,
}: ConfirmationPendingContentProps) => {
  return (
    <Wrapper>
      <Section>
        <ContentHeader onDismiss={onDismiss}>
          Waiting for confirmation
        </ContentHeader>
        <ConfirmedIcon>
          <CustomLightSpinner src={yellowSpinner} alt="loader" size="90px" />
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify="center">
          <AutoColumn gap="12px" justify="center">
            <Text fontSize="14px">
              <strong>{pendingText}</strong>
            </Text>
          </AutoColumn>
          <Text fontSize="14px" color="primary">
            Confirm this transaction in your wallet
          </Text>
        </AutoColumn>
      </Section>
    </Wrapper>
  );
};

export default ConfirmationPendingContent;
