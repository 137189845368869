// Set of helper functions to facilitate wallet setup

import { NETWORK_CHAIN_ID, NETWORK_URL } from 'connectors';
import { getBscScanAddress } from 'utils';

/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async () => {
  const provider = (window as any).ethereum;
  if (!provider) {
    console.error(
      "Can't setup the BSC network on metamask because window.ethereum is undefined"
    );
    return false;
  }
  if (!provider.request) {
    console.error(
      "Can't setup the BSC network on metamask because window.ethereum.request is undefined"
    );
    return false;
  }
  try {
    await provider.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: `0x${NETWORK_CHAIN_ID.toString(16)}`,
          chainName:
            NETWORK_CHAIN_ID === 97 ? 'BSC TESTNET' : 'Binance Smart Chain',
          nativeCurrency: {
            name: 'BNB',
            symbol: 'bnb',
            decimals: 18,
          },
          rpcUrls: [NETWORK_URL],
          blockExplorerUrls: [getBscScanAddress(NETWORK_CHAIN_ID)],
        },
      ],
    });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @param tokenImage
 * @returns {boolean} true if the token has been added, false otherwise
 */
// export const registerToken = async (
//   tokenAddress: string,
//   tokenSymbol: string,
//   tokenDecimals: number,
//   tokenImage: string,
// ) => {
//   const windowEthereum = (window as WindowChain).ethereum;
//   if(!windowEthereum?.request) {
//     return
//   }
//   await windowEthereum?.request({
//     method: 'wallet_watchAsset',
//     params: {
//       type: 'ERC20',
//       options: {
//         address: tokenAddress,
//         symbol: tokenSymbol,
//         decimals: tokenDecimals,
//         image: tokenImage,
//       },
//     },
//   })
// }
