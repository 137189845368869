import React, { useMemo } from 'react';
import { Currency, ETHER } from '@idexio/dev-idex-swap-sdk';
import { Text } from '@idexio/dev-idex-swap-uikit';
import { useActiveWeb3React } from 'hooks';
import { useSelectedTokenMap } from 'state/lists/hooks';
// import { CurrencyRow } from 'components/SearchModal/CurrencyList';
import cubeImg from './cube.png';

const HybridMarketsList = ({
  onCurrencyPairSelect,
}: {
  onCurrencyPairSelect: (
    currency: Currency,
    hybridmarketOutputCurrency: Currency
  ) => void;
}) => {
  const { chainId } = useActiveWeb3React();
  const onSelect = (tokenA: any, tokenB: any) => {
    onCurrencyPairSelect(
      tokenA.symbol?.toLowerCase() === 'bnb' ? ETHER : tokenA,
      tokenA.symbol?.toLowerCase() === 'bnb' ? ETHER : tokenB
    );
  };
  // Plan is to get hybrid markets list from API call
  const tokensMapOfChain = useSelectedTokenMap();
  const tokensMap = chainId ? tokensMapOfChain[chainId] : undefined;
  const allTokens = useMemo(
    () => (tokensMap ? Object.values(tokensMap) : tokensMap),
    [tokensMap]
  );
  const promotedMarkets = allTokens
    ? allTokens.slice(1, 4).map(token => [token.address, allTokens[0].address])
    : [];

  return (
    <>
      {tokensMap &&
        promotedMarkets.map(([tokenAAddress, tokenBAddress]) => {
          const tokenA = tokensMap[tokenAAddress];
          const tokenB = tokensMap[tokenBAddress];
          const marketSymbol = `${tokenA.symbol}-${tokenB.symbol}`;
          return (
            <div
              key={marketSymbol}
              onClick={() => onSelect(tokenA, tokenB)}
              onKeyPress={() => onSelect(tokenA, tokenB)}
              role="button"
              tabIndex={0}
            >
              <Text data-hybridlps={`${tokenA.address}-${tokenB.address}`}>
                {/* <CurrencyRow currency={tokenA} /> */}
                <img src={cubeImg} alt="hybrid" /> {marketSymbol}
              </Text>
            </div>
          );
        })}
    </>
  );
};

export default HybridMarketsList;
