import React, { useState } from 'react';
import { JSBI, Pair, Percent } from '@idexio/dev-idex-swap-sdk';
import { Button, Card, Text } from '@idexio/dev-idex-swap-uikit';
import { darken } from 'polished';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useTotalSupply } from '../../data/TotalSupply';

import { useActiveWeb3React } from '../../hooks';
import { useTokenBalance } from '../../state/wallet/hooks';
import { currencyId } from '../../utils/currencyId';
import { unwrappedToken } from '../../utils/wrappedCurrency';
import { AutoColumn } from '../Column';
import CurrencyLogo from '../CurrencyLogo';
import DoubleCurrencyLogo from '../DoubleLogo';
import { RowBetween, RowFixed } from '../Row';
import { Dots } from '../swap/styleds';

export const FirstRow = styled(RowBetween)<{ showMore: boolean }>`
  padding: 12px 16px;
  cursor: pointer;
  width: 100%;

  border-bottom: 1px solid
    ${({ theme, showMore }) =>
      showMore ? darken(0.1)(theme.colors.borderColor) : 'transparent'};
`;

export const Row = styled(RowBetween)`
  padding: 5px 16px;
`;

export const HoverCard = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 1px;
  :hover {
    border: 1px solid ${({ theme }) => darken(0.06, theme.colors.borderColor)};
  }
`;

interface PositionCardProps {
  pair: Pair;
  // eslint-disable-next-line react/no-unused-prop-types
  showUnwrapped?: boolean;
}

export function MinimalPositionCard({
  pair,
  showUnwrapped = false,
}: PositionCardProps) {
  const { account } = useActiveWeb3React();

  const currency0 = showUnwrapped ? pair.token0 : unwrappedToken(pair.token0);
  const currency1 = showUnwrapped ? pair.token1 : unwrappedToken(pair.token1);

  const [showMore, setShowMore] = useState(false);

  const userPoolBalance = useTokenBalance(
    account ?? undefined,
    pair.liquidityToken
  );
  const totalPoolTokens = useTotalSupply(pair.liquidityToken);

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(
            pair.token0,
            totalPoolTokens,
            userPoolBalance,
            false
          ),
          pair.getLiquidityValue(
            pair.token1,
            totalPoolTokens,
            userPoolBalance,
            false
          ),
        ]
      : [undefined, undefined];

  return (
    <>
      {userPoolBalance && (
        <>
          <Text
            style={{ textTransform: 'uppercase', fontWeight: 600 }}
            mb="3"
            fontSize="14px"
            data-lpaddress={pair?.liquidityToken?.address}
          >
            LP Tokens in your Wallet
          </Text>
          <Card variant="info" py="2">
            <AutoColumn>
              <Row onClick={() => setShowMore(!showMore)}>
                <RowFixed>
                  <DoubleCurrencyLogo
                    currency0={currency0}
                    currency1={currency1}
                    size={20}
                  />
                  <Text ml="2" fontSize="14px">
                    {currency0.symbol}/{currency1.symbol}
                  </Text>
                </RowFixed>
                <RowFixed>
                  <Text fontSize="14px">
                    {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
                  </Text>
                </RowFixed>
              </Row>
              <AutoColumn gap="4px">
                <Row>
                  <RowFixed>
                    <div style={{ padding: '1px 10px' }} />
                    <CurrencyLogo currency={currency0} size="20px" />
                    <Text ml="2" fontSize="14px">
                      {currency0.symbol}
                    </Text>
                  </RowFixed>
                  {token0Deposited ? (
                    <RowFixed>
                      <Text ml="6px" fontSize="14px">
                        {token0Deposited?.toSignificant(6)}
                      </Text>
                    </RowFixed>
                  ) : (
                    '-'
                  )}
                </Row>
                <Row>
                  <RowFixed>
                    <div style={{ padding: '1px 10px' }} />
                    <CurrencyLogo currency={currency1} size="20px" />
                    <Text ml="2" fontSize="14px">
                      {currency1.symbol}
                    </Text>
                  </RowFixed>
                  {token1Deposited ? (
                    <RowFixed>
                      <Text ml="6px" fontSize="14px">
                        {token1Deposited?.toSignificant(6)}
                      </Text>
                    </RowFixed>
                  ) : (
                    '-'
                  )}
                </Row>
              </AutoColumn>
            </AutoColumn>
          </Card>
        </>
      )}
    </>
  );
}

export default function FullPositionCard({ pair }: PositionCardProps) {
  const { account } = useActiveWeb3React();

  const currency0 = unwrappedToken(pair.token0);
  const currency1 = unwrappedToken(pair.token1);

  const [showMore, setShowMore] = useState(false);

  const userPoolBalance = useTokenBalance(
    account ?? undefined,
    pair.liquidityToken
  );
  const totalPoolTokens = useTotalSupply(pair.liquidityToken);

  const poolTokenPercentage =
    !!userPoolBalance &&
    !!totalPoolTokens &&
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined;

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(
            pair.token0,
            totalPoolTokens,
            userPoolBalance,
            false
          ),
          pair.getLiquidityValue(
            pair.token1,
            totalPoolTokens,
            userPoolBalance,
            false
          ),
        ]
      : [undefined, undefined];

  return (
    <HoverCard>
      <AutoColumn gap="12px" justify="stretch">
        <FirstRow showMore={showMore} onClick={() => setShowMore(!showMore)}>
          <RowFixed>
            <DoubleCurrencyLogo
              currency0={currency0}
              currency1={currency1}
              margin
              size={20}
            />
            <Text>
              {!currency0 || !currency1 ? (
                <Dots>Loading</Dots>
              ) : (
                `${currency0.symbol}/${currency1.symbol}`
              )}
            </Text>
          </RowFixed>
          <RowFixed>
            {showMore ? (
              <ChevronUp size="20" style={{ marginLeft: '10px' }} />
            ) : (
              <ChevronDown size="20" style={{ marginLeft: '10px' }} />
            )}
          </RowFixed>
        </FirstRow>
        {showMore && (
          <AutoColumn gap="8px">
            <Row>
              <RowFixed>
                <Text>Pooled {currency0.symbol}:</Text>
              </RowFixed>
              {token0Deposited ? (
                <RowFixed>
                  <Text ml="6px">{token0Deposited?.toSignificant(6)}</Text>
                  <CurrencyLogo
                    size="20px"
                    style={{ marginLeft: '8px' }}
                    currency={currency0}
                  />
                </RowFixed>
              ) : (
                '-'
              )}
            </Row>

            <Row>
              <RowFixed>
                <Text>Pooled {currency1.symbol}:</Text>
              </RowFixed>
              {token1Deposited ? (
                <RowFixed>
                  <Text ml="6px">{token1Deposited?.toSignificant(6)}</Text>
                  <CurrencyLogo
                    size="20px"
                    style={{ marginLeft: '8px' }}
                    currency={currency1}
                  />
                </RowFixed>
              ) : (
                '-'
              )}
            </Row>
            <Row>
              <Text>Your pool tokens:</Text>
              <Text>
                {userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}
              </Text>
            </Row>
            <Row>
              <Text>Your pool share:</Text>
              <Text>
                {poolTokenPercentage
                  ? `${poolTokenPercentage.toFixed(2)}%`
                  : '-'}
              </Text>
            </Row>

            <RowBetween padding="16px">
              <Button
                as={Link}
                to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}
                style={{ width: '48%' }}
              >
                Add
              </Button>
              <Button
                as={Link}
                style={{ width: '48%' }}
                to={`/remove/${currencyId(currency0)}/${currencyId(currency1)}`}
                variant="secondaryDanger"
              >
                Remove
              </Button>
            </RowBetween>
          </AutoColumn>
        )}
      </AutoColumn>
    </HoverCard>
  );
}
