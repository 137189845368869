/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { Pair, Token } from '@idexio/dev-idex-swap-sdk';

import { useActiveWeb3React } from 'hooks';
import { getProviderOrSigner, getRouterContract } from 'utils';
import { Contract } from '@ethersproject/contracts';
import { abi as ERC20Abi } from '@uniswap/v2-periphery/build/ERC20.json';
import { usePairContract } from 'hooks/useContract';

/**
 * Debug contracts
 * Include anywhere as standard react component
 */
const Debugger: React.FC = () => {
  return null;
  console.log(
    'PAIR:::',
    Pair.getAddress(
      // 0x81794f32aF0621dd32cd6b3E69435A6a6e827Dd4
      new Token(97, '0xdEeef08170B439DF8eF22ab624ad91769f6a8925', 18),
      new Token(97, '0x1287067188fbB1ca0F7e25B3E6264Aa06aaC4100', 18)
    )
  );

  return null;

  const { account, chainId, library } = useActiveWeb3React();

  // DIL-ETH LP
  const pairContract: Contract | null = usePairContract(
    '0x81794f32aF0621dd32cd6b3E69435A6a6e827Dd4'
  );

  useEffect(() => {
    if (!pairContract || !account) {
      console.log('[DEBUGGER] Not yet ready... ', { pairContract, account });
      return;
    }
    pairContract.functions
      .name()
      .then(result => console.log('[DEBUGGER] LP name', result));
    pairContract.functions
      .symbol()
      .then(result => console.log('[DEBUGGER] LP symbol', result));
    pairContract.functions
      .decimals()
      .then(result => console.log('[DEBUGGER] LP decimals', result));
    // pairContract.functions.nonces(account).then(result => console.log('[DEBUGGER] LP', { result }))
    // pairContract.functions.nonces(account).then(result => console.log('[DEBUGGER] LP', { result }))
  }, [pairContract, account]);

  return null;

  useEffect(() => {
    if (!account || !library || !chainId) {
      console.log('[DEBUGGER] Not yet ready... ', {
        account,
        library,
        chainId,
      });
      return;
    }
    console.log('[DEBUGGER] Running... ');
    const router = getRouterContract(chainId, library, account);
    console.log('[DEBUGGER] Router:', router);

    router.WETH().then(wethAddress => {
      console.log('[DEBUGGER] WETH = ', wethAddress);

      // WETH IDEX Testnet 0x1287067188fbB1ca0F7e25B3E6264Aa06aaC4100 (Wrapped Ether, WETH, 18dec)
      // WETH Pancake Mainnet 0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c (Wrapped BNB, WBNB, 18dec)
      //  - https://bscscan.com/address/0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F#readContract
      //  - https://bscscan.com/address/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c
      // Official WBNB 0xae13d989dac2f0debff460ac112a837c89baa7cd  https://www.binance.org/en/blog/what-is-wbnb/
      const erc20contract = new Contract(
        wethAddress,
        ERC20Abi,
        getProviderOrSigner(library, account)
      );
      erc20contract.functions
        .name()
        .then(result => console.log('[DEBUGGER] WETH name:', result));
      erc20contract.functions
        .symbol()
        .then(result => console.log('[DEBUGGER] WETH symbol:', result));
      erc20contract.functions
        .decimals()
        .then(result => console.log('[DEBUGGER] WETH decimals:', result));
    });
  }, [account, library, chainId]);

  return null;
};

export default Debugger;
