import React from 'react';
import {
  Currency,
  CurrencyAmount,
  Fraction,
  Percent,
} from '@idexio/dev-idex-swap-sdk';
import { Button, Text } from '@idexio/dev-idex-swap-uikit';
import { TranslateString } from 'utils/translateTextHelpers';
import { RowBetween, RowFixed } from '../../components/Row';
import CurrencyLogo from '../../components/CurrencyLogo';

export function ConfirmAddModalBottom({
  noLiquidity,
  price,
  currencyA,
  currencyB,
  parsedAmountA,
  parsedAmountB,
  poolTokenPercentage,
  onAdd,
}: {
  noLiquidity?: boolean;
  price?: Fraction;
  currencyA?: Currency;
  currencyB?: Currency;
  parsedAmountA?: CurrencyAmount;
  parsedAmountB?: CurrencyAmount;
  poolTokenPercentage?: Percent;
  onAdd: () => void;
}) {
  return (
    <>
      <RowBetween>
        <Text>{currencyA?.symbol} Deposited:</Text>
        <RowFixed>
          <CurrencyLogo currency={currencyA} />
          <Text pl="2" bold>
            {parsedAmountA?.toSignificant(6)}
          </Text>
        </RowFixed>
      </RowBetween>
      <RowBetween>
        <Text>{currencyB?.symbol} Deposited:</Text>
        <RowFixed>
          <CurrencyLogo currency={currencyB} />
          <Text pl="2" bold>
            {parsedAmountB?.toSignificant(6)}
          </Text>
        </RowFixed>
      </RowBetween>
      <RowBetween>
        <Text>Rates:</Text>
        <Text bold>
          {`1 ${currencyA?.symbol} = ${price?.toSignificant(4)} ${
            currencyB?.symbol
          }`}
        </Text>
      </RowBetween>
      <RowBetween style={{ justifyContent: 'flex-end' }}>
        <Text bold>
          {`1 ${currencyB?.symbol} = ${price?.invert().toSignificant(4)} ${
            currencyA?.symbol
          }`}
        </Text>
      </RowBetween>
      <RowBetween>
        <Text>Share of Pool:</Text>
        <Text bold>
          {noLiquidity ? '100' : poolTokenPercentage?.toSignificant(4)}%
        </Text>
      </RowBetween>
      <Button mt="3" onClick={onAdd}>
        {noLiquidity
          ? TranslateString(250, 'Create Pool & Supply')
          : TranslateString(252, 'Confirm Supply')}
      </Button>
    </>
  );
}

export default ConfirmAddModalBottom;
