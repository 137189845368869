import React from 'react';
import { Modal } from '@idexio/dev-idex-swap-uikit';
import SlippageToleranceSetting from './SlippageToleranceSetting';
import TransactionDeadlineSetting from './TransactionDeadlineSetting';

type SettingsModalProps = {
  onDismiss?: () => void;
  translateString: (translationId: number, fallback: string) => string;
};

const SettingsModal = ({
  onDismiss = () => null,
  translateString,
}: SettingsModalProps) => {
  return (
    <Modal title={translateString(1200, 'Settings')} onDismiss={onDismiss}>
      <SlippageToleranceSetting translateString={translateString} />
      <TransactionDeadlineSetting translateString={translateString} />
    </Modal>
  );
};

export default SettingsModal;
