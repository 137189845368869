import React from 'react';
import { Currency, Percent, Price } from '@idexio/dev-idex-swap-sdk';
import { Text } from '@idexio/dev-idex-swap-uikit';
import { AutoColumn } from '../../components/Column';
import { AutoRow } from '../../components/Row';
import { ONE_BIPS } from '../../constants';

export function PoolPriceBar({
  currencyA,
  currencyB,
  noLiquidity,
  poolTokenPercentage,
  price,
}: {
  currencyA: Currency;
  currencyB: Currency;
  noLiquidity?: boolean;
  poolTokenPercentage?: Percent;
  price?: Price;
}) {
  return (
    <AutoColumn gap="md">
      <AutoRow justify="space-around" gap="4px">
        <AutoColumn justify="center">
          <Text>{price?.toSignificant(6) ?? '-'}</Text>
          <Text fontSize="14px" color="textSubtle" pt={1}>
            {currencyB?.symbol} per {currencyA?.symbol}
          </Text>
        </AutoColumn>
        <AutoColumn justify="center">
          <Text>{price?.invert()?.toSignificant(6) ?? '-'}</Text>
          <Text fontSize="14px" color="textSubtle" pt={1}>
            {currencyA?.symbol} per {currencyB?.symbol}
          </Text>
        </AutoColumn>
        <AutoColumn justify="center">
          <Text>
            {noLiquidity && price
              ? '100'
              : (poolTokenPercentage?.lessThan(ONE_BIPS)
                  ? '<0.01'
                  : poolTokenPercentage?.toFixed(2)) ?? '0'}
            %
          </Text>
          <Text fontSize="14px" color="textSubtle" pt={1}>
            Share of Pool
          </Text>
        </AutoColumn>
      </AutoRow>
    </AutoColumn>
  );
}

export default PoolPriceBar;
