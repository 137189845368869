import {
  MenuEntry,
  /* menuStatus, */ menuByAppUrl,
} from '@idexio/dev-idex-swap-uikit';

// TODO: idex menuByAppUrl
const config: MenuEntry[] = menuByAppUrl();
// [
//   {
//     label: 'Home',
//     icon: 'HomeIcon',
//     href: 'https://pancakeswap.finance/',
//   },
//   {
//     label: 'Trade',
//     icon: 'TradeIcon',
//     initialOpenState: true,
//     items: [
//       {
//         label: 'Exchange',
//         href: '/swap',
//       },
//       {
//         label: 'Liquidity',
//         href: '/pool',
//       },
//     ],
//   },
//   {
//     label: 'Farms',
//     icon: 'FarmIcon',
//     href: 'https://pancakeswap.finance/farms',
//   },
//   {
//     label: 'Pools',
//     icon: 'PoolIcon',
//     href: 'https://pancakeswap.finance/syrup',
//   },
//   {
//     label: 'Lottery',
//     icon: 'TicketIcon',
//     href: 'https://pancakeswap.finance/lottery',
//   },
//   {
//     label: 'Collectibles',
//     icon: 'NftIcon',
//     href: 'https://pancakeswap.finance/nft',
//   },
//   {
//     label: 'Team Battle',
//     icon: 'TeamBattleIcon',
//     href: 'https://pancakeswap.finance/competition',
//     status: menuStatus.LIVE,
//   },
//   {
//     label: 'Teams & Profile',
//     icon: 'GroupsIcon',
//     items: [
//       {
//         label: 'Leaderboard',
//         href: 'https://pancakeswap.finance/teams',
//       },
//       {
//         label: 'Task Center',
//         href: 'https://pancakeswap.finance/profile/tasks',
//       },
//       {
//         label: 'Your Profile',
//         href: 'https://pancakeswap.finance/profile',
//       },
//     ],
//   },
//   {
//     label: 'Info',
//     icon: 'InfoIcon',
//     items: [
//       {
//         label: 'Overview',
//         href: 'https://pancakeswap.info',
//       },
//       {
//         label: 'Tokens',
//         href: 'https://pancakeswap.info/tokens',
//       },
//       {
//         label: 'Pairs',
//         href: 'https://pancakeswap.info/pairs',
//       },
//       {
//         label: 'Accounts',
//         href: 'https://pancakeswap.info/accounts',
//       },
//     ],
//   },
//   {
//     label: 'IFO',
//     icon: 'IfoIcon',
//     href: 'https://pancakeswap.finance/ifo',
//   },
//   {
//     label: 'More',
//     icon: 'MoreIcon',
//     items: [
//       {
//         label: 'Contact',
//         href: 'https://docs.pancakeswap.finance/contact-us',
//       },
//       {
//         label: 'Voting',
//         href: 'https://voting.pancakeswap.finance',
//       },
//       {
//         label: 'Github',
//         href: 'https://github.com/pancakeswap',
//       },
//       {
//         label: 'Docs',
//         href: 'https://docs.pancakeswap.finance',
//       },
//       {
//         label: 'Blog',
//         href: 'https://pancakeswap.medium.com',
//       },
//       {
//         label: 'Merch',
//         href: 'https://pancakeswap.creator-spring.com/',
//       },
//     ],
//   },
// ]

export default config;
