import React, { useCallback, useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { TransactionResponse } from '@ethersproject/providers';
import {
  Currency,
  currencyEquals,
  ETHER,
  TokenAmount,
  WETH,
} from '@idexio/dev-idex-swap-sdk';
import {
  Button,
  CardBody,
  AddIcon,
  Box,
  Text as UIKitText,
} from '@idexio/dev-idex-swap-uikit';
import { RouteComponentProps } from 'react-router-dom';
import { AutoColumn, ColumnCenter } from 'components/Column';
import CardNav from 'components/CardNav';
import CurrencyInputPanel from 'components/CurrencyInputPanel';
import { AddRemoveTabs } from 'components/NavigationTabs';
import { MinimalPositionCard } from 'components/PositionCard';
import { RowBetween } from 'components/Row';

import { PairState } from 'data/Reserves';
import { useActiveWeb3React } from 'hooks';
import { useCurrency } from 'hooks/Tokens';
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback';
import { Field } from 'state/mint/actions';
import {
  useDerivedMintInfo,
  useMintActionHandlers,
  useMintState,
} from 'state/mint/hooks';

import { useTransactionAdder } from 'state/transactions/hooks';
import {
  useIsExpertMode,
  useUserDeadline,
  useUserSlippageTolerance,
} from 'state/user/hooks';
import {
  calculateGasMargin,
  calculateSlippageAmount,
  getRouterContract,
} from 'utils';
import { maxAmountSpend } from 'utils/maxAmountSpend';
import { wrappedCurrency } from 'utils/wrappedCurrency';
import { currencyId } from 'utils/currencyId';
import ConnectWalletButton from 'components/ConnectWalletButton';
import useI18n from 'hooks/useI18n';
import AppBody from '../AppBody';
import { Dots, Wrapper } from '../Pool/styleds';
import { PoolPriceBar } from './PoolPriceBar';
import { ROUTER_ADDRESS } from '../../constants';
import FirstLiquidityProviderMessage from './FirstLiquidityProviderMessage';
import ConfirmAddModal from './ConfirmAddModal';

const ApproveButton = ({
  symbol,
  onClick,
  approval,
}: {
  symbol: string | undefined;
  onClick: () => void;
  approval: ApprovalState;
}) => {
  return (
    <Button
      onClick={onClick}
      disabled={approval === ApprovalState.PENDING}
      style={{
        width: approval !== ApprovalState.APPROVED ? '48%' : '100%',
      }}
    >
      {approval === ApprovalState.PENDING ? (
        <Dots>Approving {symbol}</Dots>
      ) : (
        `Approve ${symbol}`
      )}
    </Button>
  );
};

export default function AddLiquidity({
  match: {
    params: { currencyIdA, currencyIdB },
  },
  history,
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
  const { account, chainId, library } = useActiveWeb3React();
  const currencyA = useCurrency(currencyIdA);
  const currencyB = useCurrency(currencyIdB);

  const TranslateString = useI18n();

  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [txHash, setTxHash] = useState<string>('');
  // modal and loading
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false); // clicked confirm

  const oneCurrencyIsWBNB = Boolean(
    chainId &&
      ((currencyA && currencyEquals(currencyA, WETH[chainId])) ||
        (currencyB && currencyEquals(currencyB, WETH[chainId])))
  );
  const expertMode = useIsExpertMode();

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState();
  const {
    dependentField,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error: mintError,
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined);

  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity);

  const isValid = !mintError;

  // txn values
  const [deadline] = useUserDeadline(); // custom from users settings
  const [allowedSlippage] = useUserSlippageTolerance(); // custom from users

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: noLiquidity
      ? otherTypedValue
      : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  };

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: TokenAmount } = [
    Field.CURRENCY_A,
    Field.CURRENCY_B,
  ].reduce((accumulator, field) => {
    return {
      ...accumulator,
      [field]: maxAmountSpend(currencyBalances[field]),
    };
  }, {});

  const atMaxAmounts: { [field in Field]?: TokenAmount } = [
    Field.CURRENCY_A,
    Field.CURRENCY_B,
  ].reduce((accumulator, field) => {
    return {
      ...accumulator,
      [field]: maxAmounts[field]?.equalTo(parsedAmounts[field] ?? '0'),
    };
  }, {});

  const {
    [Field.CURRENCY_A]: parsedAmountA,
    [Field.CURRENCY_B]: parsedAmountB,
  } = parsedAmounts;

  // check whether the user has approved the router on the tokens
  const [approvalA, approveACallback] = useApproveCallback(
    parsedAmountA,
    ROUTER_ADDRESS
  );
  const [approvalB, approveBCallback] = useApproveCallback(
    parsedAmountB,
    ROUTER_ADDRESS
  );

  const addTransaction = useTransactionAdder();

  async function onAdd() {
    if (!chainId || !library || !account) {
      return;
    }
    const router = getRouterContract(chainId, library, account);

    if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB) {
      return;
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(
        parsedAmountA,
        noLiquidity ? 0 : allowedSlippage
      )[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(
        parsedAmountB,
        noLiquidity ? 0 : allowedSlippage
      )[0],
    };

    const deadlineFromNow = Math.ceil(Date.now() / 1000) + deadline;

    let estimate;
    let method: (...args: any) => Promise<TransactionResponse>;
    let args: Array<string | string[] | number>;
    let value: BigNumber | null;
    if (currencyA === ETHER || currencyB === ETHER) {
      const tokenBIsBNB = currencyB === ETHER;
      estimate = router.estimateGas.addLiquidityETH;
      method = router.addLiquidityETH;
      args = [
        wrappedCurrency(tokenBIsBNB ? currencyA : currencyB, chainId)
          ?.address ?? '', // token
        (tokenBIsBNB ? parsedAmountA : parsedAmountB).raw.toString(), // token desired
        amountsMin[
          tokenBIsBNB ? Field.CURRENCY_A : Field.CURRENCY_B
        ].toString(), // token min
        amountsMin[
          tokenBIsBNB ? Field.CURRENCY_B : Field.CURRENCY_A
        ].toString(), // eth min
        account,
        deadlineFromNow,
      ];
      value = BigNumber.from(
        (tokenBIsBNB ? parsedAmountB : parsedAmountA).raw.toString()
      );
    } else {
      estimate = router.estimateGas.addLiquidity;
      method = router.addLiquidity;
      args = [
        wrappedCurrency(currencyA, chainId)?.address ?? '',
        wrappedCurrency(currencyB, chainId)?.address ?? '',
        parsedAmountA.raw.toString(),
        parsedAmountB.raw.toString(),
        amountsMin[Field.CURRENCY_A].toString(),
        amountsMin[Field.CURRENCY_B].toString(),
        account,
        deadlineFromNow,
      ];
      value = null;
    }

    setAttemptingTxn(true);
    // const aa = await estimate(...args, value ? { value } : {})
    await estimate(...args, value ? { value } : {})
      .then(estimatedGasLimit =>
        method(...args, {
          ...(value ? { value } : {}),
          gasLimit: calculateGasMargin(estimatedGasLimit),
        }).then(response => {
          setAttemptingTxn(false);

          addTransaction(response, {
            summary: `Add ${parsedAmountA?.toSignificant(3)} ${
              currencyA?.symbol
            } and ${parsedAmountB?.toSignificant(3)} ${currencyB?.symbol}`,
          });

          setTxHash(response.hash);
        })
      )
      .catch(error => {
        setAttemptingTxn(false);
        // we only care if the error is something _other_ than the user rejected the tx
        if (error?.code !== 4001) {
          console.error(error);
        }
      });
  }

  const handleCurrencyASelect = useCallback(
    (currA: Currency) => {
      const newCurrencyIdA = currencyId(currA);
      if (newCurrencyIdA === currencyIdB) {
        history.push(`/add/${currencyIdB}/${currencyIdA}`);
      } else {
        history.push(`/add/${newCurrencyIdA}/${currencyIdB}`);
      }
    },
    [currencyIdB, history, currencyIdA]
  );
  const handleCurrencyBSelect = useCallback(
    (currB: Currency) => {
      const newCurrencyIdB = currencyId(currB);
      if (currencyIdA === newCurrencyIdB) {
        if (currencyIdB) {
          history.push(`/add/${currencyIdB}/${newCurrencyIdB}`);
        } else {
          history.push(`/add/${newCurrencyIdB}`);
        }
      } else {
        history.push(`/add/${currencyIdA || 'BNB'}/${newCurrencyIdB}`);
      }
    },
    [currencyIdA, history, currencyIdB]
  );

  return (
    <>
      <CardNav activeIndex={1} />
      <AppBody>
        <AddRemoveTabs adding />
        <Wrapper>
          {showConfirm && (
            <ConfirmAddModal
              amountA={parsedAmountA}
              amountB={parsedAmountB}
              currencyA={currencyA}
              currencyB={currencyB}
              txHash={txHash}
              attemptingTxn={attemptingTxn}
              noLiquidity={noLiquidity}
              liquidityMinted={liquidityMinted}
              poolTokenPercentage={poolTokenPercentage}
              price={price}
              onAdd={onAdd}
              onClose={() => {
                setShowConfirm(false);
                // if there was a tx hash, we want to clear the input
                if (txHash) {
                  onFieldAInput('');
                }
                setTxHash('');
              }}
            />
          )}
          <CardBody>
            <AutoColumn gap="20px">
              {noLiquidity && <FirstLiquidityProviderMessage />}
              <CurrencyInputPanel
                value={formattedAmounts[Field.CURRENCY_A]}
                onUserInput={onFieldAInput}
                onMax={() => {
                  onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toExact() ?? '');
                }}
                onCurrencySelect={handleCurrencyASelect}
                showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
                currency={currencyA}
                id="add-liquidity-input-tokena"
                showCommonBases={false}
              />
              <ColumnCenter>
                <AddIcon color="secondary" />
              </ColumnCenter>
              <CurrencyInputPanel
                value={formattedAmounts[Field.CURRENCY_B]}
                onUserInput={onFieldBInput}
                onCurrencySelect={handleCurrencyBSelect}
                onMax={() => {
                  onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toExact() ?? '');
                }}
                showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
                currency={currencyB}
                id="add-liquidity-input-tokenb"
                showCommonBases={false}
              />
              {currencyA && currencyB && pairState !== PairState.INVALID && (
                <Box py="2" px="3" border="1px solid #515158">
                  <UIKitText fontSize="14px" mb="2px">
                    {noLiquidity
                      ? TranslateString(1164, 'Initial prices and pool share')
                      : TranslateString(1166, 'Prices and pool share')}
                  </UIKitText>
                  <Box py="3">
                    <PoolPriceBar
                      currencyA={currencyA}
                      currencyB={currencyB}
                      poolTokenPercentage={poolTokenPercentage}
                      noLiquidity={noLiquidity}
                      price={price}
                    />
                  </Box>
                </Box>
              )}

              {!account ? (
                <ConnectWalletButton width="100%" />
              ) : (
                <AutoColumn gap="md">
                  {(approvalA === ApprovalState.NOT_APPROVED ||
                    approvalA === ApprovalState.PENDING ||
                    approvalB === ApprovalState.NOT_APPROVED ||
                    approvalB === ApprovalState.PENDING) &&
                    isValid && (
                      <RowBetween>
                        {approvalA !== ApprovalState.APPROVED && (
                          <ApproveButton
                            approval={approvalA}
                            symbol={currencyA?.symbol}
                            onClick={approveACallback}
                          />
                        )}
                        {approvalB !== ApprovalState.APPROVED && (
                          <ApproveButton
                            approval={approvalB}
                            symbol={currencyB?.symbol}
                            onClick={approveBCallback}
                          />
                        )}
                      </RowBetween>
                    )}
                  <Button
                    onClick={() => {
                      if (expertMode) {
                        onAdd();
                      } else {
                        setShowConfirm(true);
                      }
                    }}
                    disabled={
                      !isValid ||
                      approvalA !== ApprovalState.APPROVED ||
                      approvalB !== ApprovalState.APPROVED
                    }
                    variant={
                      !isValid &&
                      !!parsedAmounts[Field.CURRENCY_A] &&
                      !!parsedAmounts[Field.CURRENCY_B]
                        ? 'danger'
                        : 'primary'
                    }
                    width="100%"
                  >
                    {mintError ?? 'Supply'}
                  </Button>
                </AutoColumn>
              )}
            </AutoColumn>
          </CardBody>
        </Wrapper>
      </AppBody>
      {pair && !noLiquidity && pairState !== PairState.INVALID ? (
        <Box mt="3" px="4" maxWidth="436px" width="100%">
          <MinimalPositionCard showUnwrapped={oneCurrencyIsWBNB} pair={pair} />
        </Box>
      ) : null}
    </>
  );
}
