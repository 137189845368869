import React from 'react';
import styled from 'styled-components/macro';
import { Text } from '@idexio/dev-idex-swap-uikit';

import { AutoColumn, ColumnCenter } from 'components/Column';
import { TranslateString } from 'utils/translateTextHelpers';

const Pane = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 2px;
  padding: 16px;
`;

export default function FirstLiquidityProviderMessage() {
  return (
    <ColumnCenter>
      <Pane>
        <AutoColumn gap="12px">
          <Text>
            {TranslateString(1158, 'You are the first liquidity provider.')}
          </Text>
          <Text>
            {TranslateString(
              1160,
              'The ratio of tokens you add will set the price of this pool.'
            )}
          </Text>
          <Text>
            {TranslateString(
              1162,
              'Once you are happy with the rate click supply to review.'
            )}
          </Text>
        </AutoColumn>
      </Pane>
    </ColumnCenter>
  );
}
