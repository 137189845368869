import { useMemo } from 'react';
import { Toast, toastTypes } from '@idexio/dev-idex-swap-uikit';
import { useDispatch } from 'react-redux';

import { convertToKebabCase } from 'utils/string';
import {
  push as pushToast,
  remove as removeToast,
  clear as clearToast,
} from 'state/actions';

// Toasts
const useToast = () => {
  const dispatch = useDispatch();
  const helpers = useMemo(() => {
    const push = (toast: Toast) => dispatch(pushToast(toast));

    return {
      toastError: (title: string, description?: string) => {
        if (!title && !description) {
          console.error('NO TOAST ERROR available');
        }
        return push({
          id: convertToKebabCase(title),
          type: toastTypes.DANGER,
          title,
          description,
        });
      },
      toastInfo: (title: string, description?: string) => {
        return push({
          id: convertToKebabCase(title),
          type: toastTypes.INFO,
          title,
          description,
        });
      },
      toastSuccess: (title: string, description?: string) => {
        return push({
          id: convertToKebabCase(title),
          type: toastTypes.SUCCESS,
          title,
          description,
        });
      },
      toastWarning: (title: string, description?: string) => {
        return push({
          id: convertToKebabCase(title),
          type: toastTypes.WARNING,
          title,
          description,
        });
      },
      push,
      remove: (id: string) => dispatch(removeToast(id)),
      clear: () => dispatch(clearToast()),
    };
  }, [dispatch]);

  return helpers;
};

export default useToast;
