import React from 'react';
import { ThemeProvider as SCThemeProvider } from 'styled-components/macro';
import { light, dark } from '@idexio/dev-idex-swap-uikit';

// const CACHE_KEY = 'IS_DARK'
export interface ThemeContextType {
  isDark: boolean;
  toggleTheme: () => void;
}

const ThemeContext = React.createContext<ThemeContextType>({
  isDark: true,
  toggleTheme: () => null,
});

const ThemeContextProvider: React.FC = ({ children }) => {
  // const [isDark, setIsDark] = useState(() => {
  //   const isDarkUserSetting = localStorage.getItem(CACHE_KEY)
  //   return isDarkUserSetting ? JSON.parse(isDarkUserSetting) : false
  // })

  // const toggleTheme = () => {
  //   setIsDark((prevState: any) => {
  //     localStorage.setItem(CACHE_KEY, JSON.stringify(!prevState))
  //     return !prevState
  //   })
  // }
  const isDark = true;
  const toggleTheme = () => {
    // nothing..
  };

  return (
    <ThemeContext.Provider value={{ isDark, toggleTheme }}>
      <SCThemeProvider theme={isDark ? dark : light}>
        {children}
      </SCThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeContextProvider };
