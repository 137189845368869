import React from 'react';
import {
  Currency,
  CurrencyAmount,
  Percent,
  Price,
  TokenAmount,
} from '@idexio/dev-idex-swap-sdk';
import { Text, Card, Flex } from '@idexio/dev-idex-swap-uikit';

import { TranslateString } from 'utils/translateTextHelpers';
import TransactionConfirmationModal, {
  ConfirmationModalContent,
} from 'components/TransactionConfirmationModal';
import { AutoColumn } from 'components/Column';
import { LightCard } from 'components/Card';
import Row, { RowFlat } from 'components/Row';
import DoubleCurrencyLogo from 'components/DoubleLogo';
import { useUserSlippageTolerance } from 'state/user/hooks';
import { ConfirmAddModalBottom } from './ConfirmAddModalBottom';

type Props = {
  currencyA?: Currency | null;
  amountA?: CurrencyAmount;
  currencyB?: Currency | null;
  amountB?: CurrencyAmount;
  noLiquidity?: boolean;
  txHash?: string;
  liquidityMinted?: TokenAmount;
  attemptingTxn: boolean;
  poolTokenPercentage?: Percent;
  price?: Price;
  onAdd: () => void;
  onClose: () => void;
};

export default function ConfirmAddModal({
  currencyA,
  currencyB,
  amountA,
  amountB,
  txHash,
  noLiquidity,
  attemptingTxn,
  poolTokenPercentage,
  price,
  liquidityMinted,
  onAdd,
  onClose,
}: Props) {
  const [allowedSlippage] = useUserSlippageTolerance(); // custom from users

  const symbolA = currencyA?.symbol;
  const symbolB = currencyB?.symbol;
  const pendingText = `Supplying ${amountA?.toSignificant(
    6
  )} ${symbolA} and ${amountB?.toSignificant(6)} ${symbolB}`;

  const modalHeader = () => {
    return noLiquidity ? (
      <AutoColumn gap="20px">
        <Card mt="3">
          <Flex alignItems="center">
            <Text fontSize="30px" mr="2" bold>
              {`${symbolA}/${symbolB}`}
            </Text>
            <DoubleCurrencyLogo
              currency0={currencyA}
              currency1={currencyB}
              size={30}
            />
          </Flex>
        </Card>
      </AutoColumn>
    ) : (
      <AutoColumn gap="20px">
        <Card py="3" px="4" mt="3" solidBorders>
          <Flex alignItems="center">
            <Text fontSize="30px" mr="2" bold>
              {liquidityMinted?.toSignificant(6)}
            </Text>
            <DoubleCurrencyLogo
              currency0={currencyA}
              currency1={currencyB}
              size={30}
            />
          </Flex>
          <Text color="textSubtle">{`${symbolA}/${symbolB} Pool Tokens`}</Text>
        </Card>
        <Card variant="info" px="4" py="3">
          <Text>
            {`Output is estimated. If the price changes by more than ${
              allowedSlippage / 100
            }% your transaction will revert.`}
          </Text>
        </Card>
      </AutoColumn>
    );
  };
  return (
    <TransactionConfirmationModal
      isOpen
      onDismiss={onClose}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      content={() => (
        <ConfirmationModalContent
          title={
            noLiquidity
              ? TranslateString(1154, 'You are creating a pool')
              : TranslateString(1156, 'You will receive')
          }
          onDismiss={onClose}
          topContent={modalHeader}
          bottomContent={() => (
            <ConfirmAddModalBottom
              price={price}
              currencyA={currencyA ?? undefined}
              currencyB={currencyB ?? undefined}
              parsedAmountA={amountA}
              parsedAmountB={amountB}
              noLiquidity={noLiquidity}
              onAdd={onAdd}
              poolTokenPercentage={poolTokenPercentage}
            />
          )}
        />
      )}
      pendingText={pendingText}
    />
  );
}
